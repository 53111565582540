
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { GetStaticPaths, GetStaticProps } from 'next';
import { IStaticPropsPage, LOCALE } from 'src/lib/types/index';
import {
  getFooter,
  getMenu,
  getPageTypeSpecifics,
  getSiteTree,
} from '@lib/api';
import { pageTypeToApiCall, slugArrayToPageType } from '@lib/utils';
import {
  transformContentModel,
  transformPageTypeSpecificData,
} from '@lib/utils/transformContent';

import Header from '@components/Header';
import Layout from '@components/Layout';
import LoadingIndicator from '@components/LoadingIndicator';
import Meta from '@components/Meta';
import { ParsedUrlQuery } from 'querystring';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

export default function Index({
  preview,
  pageType,
  pageTypeSpecificData,
  pageData,
  footerData,
  menuData,
  locale,
  locales,
}: IStaticPropsPage) {
  const router = useRouter();
  const { t } = useTranslation();
  const heroContent = transformContentModel(pageData?.topSection, t);
  const footerContent = transformContentModel(footerData, t);
  const pageContent = pageData?.pageContentCollection?.items?.map(
    (contentObject) => transformContentModel(contentObject, t)
  );
  const pageTypeSpecificContent = transformPageTypeSpecificData(
    pageTypeSpecificData,
    t
  );

  if (router.isFallback) {
    return (
      <div className='flex min-w-full min-h-screen place-content-center place-items-center'>
        <LoadingIndicator width={48} height={48} />
      </div>
    );
  }

  return (
    <>
      <Header locale={locale} locales={locales} topLinks={menuData} />
      {pageData?.seo ? <Meta fields={pageData.seo} /> : null}
      <Layout
        pageType={pageType}
        heroContent={heroContent}
        pageTypeSpecificContent={pageTypeSpecificContent}
        pageContent={pageContent}
      />
      {footerContent ?? null}
    </>
  );
}

export const getStaticPaths: GetStaticPaths = async (context) => {
  const locales = context.locales as LOCALE[];
  const siteTree = await Promise.all(
    locales.map(async (locale) => await getSiteTree({ locale }))
  );
  const flatSiteTree = siteTree.flat();

  return {
    paths: flatSiteTree,
    fallback: true,
  };
};

interface IParams extends ParsedUrlQuery {
  slug?: string[];
}

 const _getStaticProps: GetStaticProps<IStaticPropsPage, IParams> = async (
  context
) => {
  try {
    const preview = context.preview ?? false;
    // Slug is undefined at the root path
    // but an array of strings everywhere else
    const slug = context.params?.slug ?? ([] as []);
    const locale = context.locale as LOCALE;
    const locales = context.locales as LOCALE[];
    const pageType = slugArrayToPageType(slug);
    const pageSlug = slug[slug.length - 1]; // Undefined for the root path
    const pageData = await pageTypeToApiCall(pageType)({
      preview,
      locale,
      slug: pageSlug,
    });
    const menuData = await getMenu({ locale });
    const footerData = await getFooter({ locale });
    const pageTypeSpecificData = await getPageTypeSpecifics({
      preview,
      locale,
      pageType,
    });

    return {
      props: {
        preview,
        pageType,
        pageTypeSpecificData,
        pageData,
        footerData,
        menuData,
        locale,
        locales,
      },
    };
  } catch (error) {
    return {
      notFound: true,
    };
  }
};


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/[[...slug]]',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  